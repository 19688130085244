.checkout-title{
    margin:0;
    padding:0;
    font-size:$font-size-base;
    font-weight:$font-weight-bold;
}

.checkout{

    &__overview{
        position:sticky;
        top:1rem;
    }

    &__block{
        margin:1rem 0rem;
        padding:1.6rem;
        background:$light;

        @include media-breakpoint-up(sm){
            padding:3rem;
        }
    }

    &__title{
        font-size:$font-size-xl;
        margin-bottom:1rem;
        font-weight:$font-weight-bold;
    }

    &__checks{
        margin:2rem;

        a{
            color:$success;
        }
    }

    &__actions{
        margin:2rem;
        a{
            margin-top:2rem;
            display:block;
        }
    }

    &__help{
        margin:5rem 2rem 3rem 2rem;
        padding:2rem 2rem;
        background:$secondary;
        color:$primary;
        font-size:$font-size-lg;
        display:flex;
        justify-content: center;
        align-items:center;
        border-radius:4px;

        .icon{
            font-size:4rem;
            margin-right:2rem;
        }

        .text span{
            display:block;
            padding-top:0.2rem;

            span{
                color:$info;
                font-weight:$font-weight-bold;
            }
        }
    }
}