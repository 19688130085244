
.header {
    &-top {
        background: $primary;
        padding: 14px 0px !important;

        &--light {
            background: lighten($primary, 60%);
            color: $primary;
        }
    }

    &-middle {
        padding: 0.8rem 0;
        position: relative;

        @include media-breakpoint-up(sm) {
            padding: 0 0;
        }

        &__items {
            height: 100%;
            align-items: center;
            display: flex;
            position: absolute;
            width: 49%;
            bottom: 0;
            z-index: 99;
            letter-spacing: -1px;
        }

    }
}

.languages {
    margin-top: .2rem;
    margin-left: 7.5rem;
    position: relative;
    z-index: 99;

    @media only screen and (max-width: 991px) {
        margin-left: 0rem;
    }

    &__link {
        color: $white;
    }
}

.language-item {
    color: $white;
    margin-right: .2rem;

    &:after {
        content: ' |';
    }

    &:last-child:after {
        content: '';
    }
    &:hover {
        color: $success;
    }
}

.logo {
    color: $info;
    text-decoration: none;
    display: flex;
    align-items: center;
    position: relative;
    margin-top: -2.85rem;
    
    @media only screen and (max-width: 991px) {
        margin-left: auto;
        margin-right: auto;
        
    }
    @media only screen and (max-width: 767px) {
        margin-top: 0rem;
    }

    img {
        @include media-breakpoint-down(sm) {
            /*max-width: 10rem;*/
            width: 90%;
            max-width: inherit;            
        }
    }

    &:hover {
        text-decoration: none;
    }


    &__title {
        line-height: 1.1;
        font-weight: $font-weight-bold;
        font-size: 1.3rem;
        padding-left: 0.5rem;

        @include media-breakpoint-down(sm) {
            font-size: 1rem;
        }
    }
}

.top-nav {

    &__link {
        font-size: 1.2rem;
        padding: 0rem 1rem;
        border-right: 1px $white solid;
        color: $white;

        @include media-breakpoint-down(sm) {
            font-size: 1rem;
            padding: 0rem 0.6rem;
        }

        &--user {
            &+.dropdown-menu {
                min-width: 15rem;
            }
        }

        &--cart {
            border-right: 0;
            padding-right: 0;
            position: relative;

            #cart-count {
                position: absolute;
                top: -0.4rem;
                right: -0.4rem;
                background: $white;
                font-size: 0.8rem;
                line-height: 1;
                width: 1rem;
                height: 1rem;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                color: $primary;
                font-weight: $font-weight-bold;

                &:empty {
                    display: none;
                }
            }
        }

        &:hover {
            color: $success;
        }
    }
}