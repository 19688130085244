.products-slider-wrapper{	
	position:relative;
	padding:2rem 0;

	.product{
		margin-bottom:0;
	}

	.slider-arrows{
		display:flex;
		justify-content:center;
		position:absolute;
		top:100%;
		left:0;
		right:0;
		transform:translateY(-50%);

		@include media-breakpoint-up(sm) {
			justify-content: space-between;
			top:50%;
			z-index:1;
			left:-3rem;
			right:-3rem;
			transform:translateY(-50%);
		}

	}
    
    .tns-outer{
        z-index:2;
    }
	
	.slider-arrow{
		font-size:2rem;
		cursor:pointer;
		padding:0rem 0.5rem;
		transition:all 0.5s;
		outline:0;
	
		&:hover{
			color:$success;
		}
	}
}



.product{
	position:relative;
	margin-bottom:2rem;
	background:$white;

	.ItemTransparency {
		img {
			opacity: 0.4;
			-moz-opacity: 0.4;
			-khtml-opacity: 0.4;
			filter: alpha(opacity=40);
			-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";			
		}
	}

	&__photo{
		border-bottom:1px $gray-300 solid;
		background:$white;
		height:17rem;
		display:flex;
		justify-content: center;
		align-items: center;
		overflow:hidden;

		img{
			transition:transform 0.4s;
		}
	}

	&__title{
		line-height:1.1;
		height:2.8rem;
		overflow:hidden;
		margin:1rem 1rem 0.5rem;
		display:flex;
		align-items:center;
		justify-content: flex-start;
	}

	&__discount{
		position:absolute;
		top:1rem;
		left:1rem;
		background-color:$success;
		color:$white;
		display:flex;
		align-items:center;
		justify-content:center;
		border-radius:50%;
		width:3rem;
		height:3rem;
		font-weight:500;
	}
	
	&__unavailable{
		position:absolute;
		top:1rem;
		right:1rem;
		background-color:$gray-600;
		color:$white;
		display:flex;
		align-items:center;
		justify-content:center;
		border-radius:50%;
		width:6rem;
		height:3rem;
		font-weight:500;
		text-align: center;
		line-height: 1.2;
		padding: 0px 10px;
		font-size: 0.8rem;
	}
	
	&__price{
		color: $success;
		padding:0.5rem 1rem 1rem;
		font-size:1.1rem;
		font-weight:$font-weight-bold;

		
		@include media-breakpoint-down(sm) {
			padding:0.5rem 0.5rem 1rem;
		}

		span{
			font-size:0.8rem;
			font-weight:$font-weight-base;
			text-decoration:line-through;
			color:$gray-900;
		}

	}

	&__actions{
		display:flex;
		padding:0.5rem 1rem 1rem;

		@media screen and (max-width: 360px) {
			display:block;
		}

		@include media-breakpoint-down(sm) {
			padding:0.5rem 0.5rem 1rem;
		}
	}

	&__action{
		width:2.4rem;
		height:2.4rem;
		display:flex;
		align-items:center;
		justify-content:center;
		font-size:1.1rem;
		border-radius:50%;
		color:$white;

		@media screen and (max-width: 360px) {
			margin-bottom: 0.3rem;
		}

		&:hover{
			color:$white;
		}

		&--buy{
			background-color:$success;

			&:hover{
				background-color:lighten($success, 10%);
			}
		}

		&--more{
			background-color:$black;
			margin-right:0.4rem;

			&:hover{
				background-color:lighten($info, 10%);
			}
		}

		&--wishlist{
			background-color:$primary;
			margin-right:0.5rem;

			&:hover{
				background-color:lighten($primary, 10%);
			}
		}

		.btn-title{
			display:none;
		}
		
	}

	&:hover{

		.product__photo img{
			transform: scale(1.1);
		}

	}

	&--simple{
		padding:1rem;
		margin-bottom:0;
		border-bottom:1px $gray-300 solid;

		.product__photo{
			height:auto;
			border:1px $gray-300 solid;
		}

		.product__title{
			height:auto;
			margin:0 0 0.4rem 1rem;
		}

		.product__price{
			padding-top:0;
			padding-bottom:0;
		}

		&:hover{
			background:$gray-100;
		}
	}

}