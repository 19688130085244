.steps{
    display:flex;
    justify-content: space-between;
    width:100%;
    align-items: center;
}

.step{
    display:flex;
    justify-content: flex-end;
    align-items:center;
    flex: 1 1 auto;
    color:lighten($secondary, 10%);

    &:before{
        content:"";
        display:block;
        height:1px;
        background:$secondary;
        width:100%;
    }

    &:first-child:before{
        display:none;
    }

    &__icon{
        display:none;
    }

    &__number{
        width:3rem;
        height:3rem;
        color:$secondary;
        border-radius:50%;
        margin-right:0.5rem;
        border:1px $secondary solid;
        flex-shrink: 0;
        display:flex;
        align-items:center;
        justify-content: center;
        text-align:center;
        margin-left:0.5rem;
        font-size:$font-size-lg;
    }

    &__text{
        flex-shrink: 1;
        margin-right:0.5rem;
    }

    @include media-breakpoint-only(xs){
        font-size:$font-size-sm;

        &__number{
            width:2.4rem;
            height:2.4rem;
            font-size:$font-size-base;
            margin-right:0.3rem;
            margin-left:0.3rem;
        }
    }

    &--current{
        color:$success;
        font-weight:$font-weight-bold;
        
        &:before{
            background-color:$success;
        }

        .step__number{
            color:$white;
            border-color:$white;
            background-color:$success;
        }
    }

    &--completed{
        color:$white;

        &:before{
            background-color:$success;
        }

        .step__number{
            color:$white;
            border-color:$success;
            background-color:$primary;
            font-size:$font-size-lg;
        }
    }
}