.filters{
    margin:1rem 0 2rem;

    &__title{
        padding:0.5rem 0rem;
        color:$primary;
        margin-bottom:0.3rem;
        border-bottom:1px $primary solid;
        text-align:center;
        font-weight:$font-weight-bold;
        display:flex;
        align-items:center;
        justify-content: center;
    }

    &__clear{
        color:$primary;
        transition:color 0.5s;

        margin-left:auto;

        &:hover{
            color:$danger;
        }
    }

    &__content{
        max-height:20rem;
        overflow:auto;

        ul{
            padding:0;
            margin:0;
            list-style:none;
        }

        li:last-child{
            border:0;
        }
    }

}

.filter{
    border-bottom:1px $gray-400 solid;

    &__link{
        display:flex !important;
        justify-content: flex-start;
        align-items: center;
        padding:0.3rem 1px !important;
        margin:0.1rem 0;
        transition: all 0.5s;
        color:$gray-500;
        

        &:hover{
            color:darken($gray-500, 50%);
        }
    }

    &__check{
        display:inline-block;
        width:1rem;
        height:1rem;
        background-color:$white;
        outline:1px $gray-500 solid;
        margin-right:0.7rem;
        flex-shrink: 0;
    }

    &:hover{

        .filter__check{
            outline:1px darken($gray-500, 50%) solid;
        }

        .filter__link{
            text-decoration:none;
        }
        
    }

    &--active{
        .filter__check{
            border:2px $white solid;
            background-color:$success;
        }
    }
}