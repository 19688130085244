.navbar-toggler{
	padding-left:0;

	@media only screen and (min-width: 768px) {
        display:none;
    }
}

.menu {

	&__icon{
		display:flex;
		align-items:center;
		font-size:1.8rem;
		margin-right:0.4rem;
		color:$success;
	}

	.navbar-nav{
		width:100%;
		justify-content:space-between;
		padding:0;
		margin:0;		
		height: 90px;
		list-style:none;

		@media only screen and (max-width: 767px) {
			display: table;
		}
	}

	.nav-item{
		display:flex;
		flex-direction: column;
		flex-basis:100%;
		align-items:center;
		justify-content: center;
		width:100%;
		color:$success;
		transition:background-color 0.5s;

		@include media-breakpoint-down(lg){
			padding-left: -1rem;
			padding-right: -1rem;
		}

		

		

		&:hover{
			background-color:$success;

			.nav-link{
				color:$primary !important;
			}

		}

	}

	.nav-link{
		text-align:center;
		color:$primary;
		font-weight:bold;
		font-size:1rem;
		line-height:1;
		width:100%;
		white-space: nowrap;
		padding-right: 2.3125rem !important;
    	padding-left: 2.3125rem !important;

		@include media-breakpoint-down(lg){
			font-size:0.9rem;			
		}

		@media only screen and (max-width: 1200px) and (min-width: 991px) {			
			padding-right: 1.3125rem !important;
    		padding-left: 1.3125rem !important;
		}

		span{
			color:$primary;
			display:block;
		}

	}

	.nav-sublink{
		color:$primary; 

		&.more-link{
			color: $success !important;
			text-decoration:underline;
		}

	}

}


.nav-submenu{
	visibility: hidden;
	opacity:0;display:none;
	transform:translateY(3rem);
	transition: opacity 0.5s, transform 0.5s, visibility 0.5s;
	width: 500px;
	background:$white;
	padding: 0rem 1rem 1rem 1rem;
	z-index:15;
	border-left:1px darken($secondary, 10%) solid;
	border-right:1px darken($secondary, 10%) solid;
	border-bottom:1px darken($secondary, 10%) solid;
	
	@include media-breakpoint-down(sm){
		width:475px;
	}

	@media (max-width: 480px){
		width:306px;
	}

	@include media-breakpoint-up(md){
		background:$white;
		position:absolute;
		top:100%;
		left:0;
		right:0;
		padding-top: 1rem;
	}

	&--active{
		display:block;
	}

	&--show{
		visibility: visible;
		opacity:1;
		transform:translateY(0);
	}

	.subitem-1{
		display:flex;
		flex-wrap:wrap;
		padding:0;
		margin:0;
		list-style:none;

		> li{
			width:100%;
			padding: 0 1rem 1rem 1rem;

			@include media-breakpoint-down(md){
				width:33.333%;
			}

			@include media-breakpoint-down(sm){
				width:50%;
			}

			> a{
				display:block;
				color:$primary;
				padding-bottom:0.4rem;
				font-weight:600;
			}
		}
	}

	.subitem-2{
		padding:0;
		margin:0;
		list-style:none;
	}

}

.inside-menu-nav{
	background-color:$info;
	padding:1rem;
	color:$white;

	&__title{
		font-weight:bold;
		border-bottom:1px $secondary solid;
		padding-bottom:0.5rem;
	}

	&__items{
		padding:1rem 0 0;
		list-style:none;

		a{
			color:$white;
			display:flex;
			align-items:center;
			justify-content: space-between;
		}

	}

}