.footer{
	background:$primary;
	padding-top:3rem;

	.text-green-footer {
		color:$success;
	}

	.espa-banner {
		display: flex;
    	align-items: flex-end;
	}
	.container {
		text-align: center;
	}
}


.social{
	display:inline-flex;
	/*
	lign-items: center;
	justify-content:flex-end;
	*/
	color: $white;
	font-size:$font-size-lg;
	line-height: 2;
	
	@include media-breakpoint-down(sm){
		justify-content:center;
	}

	&__link{
		display:flex;
		align-items:center;
		justify-content: center;
		width:2.2rem;
		height:2.2rem;
		border-radius:50%;
		background-color:$primary;
		margin-left:0.5rem;
		color:$white;
		transition: all 0.5s;

		&:hover{
			background-color:$secondary;
		}
	}
}

.footer-block{

	&__title{
		color:$white;
		margin-top:0.6rem;
		margin-bottom:0.6rem;
		font-weight:500;
		font-size:1.1rem;
	}

	&__content{
		color: $white;
		margin-bottom:0.6rem !important;
	}

}

.inline-menu ul{
	display:flex;
	padding:0;
	margin:0;
	list-style:none;

	@include media-breakpoint-down(sm){
		justify-content:center;
	}

	li{
		margin-right:0.7rem;
	}

}

.cardlogos {
	text-align: center;
	background-color:$white;
	padding: 20px 0px;
}