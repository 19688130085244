.page-description{
    position:relative;
	height: 236px;

    h1{
        position:absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        display:flex;
        align-items:center;
        justify-content: center;
        color:$black;
        font-weight: bold;   
        font-size: 3.75rem;     
    }

    &__image{
        object-fit: cover;
        min-height:6rem;
        width:100%;
    }
}