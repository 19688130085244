.product-view{

    color:$primary;
    margin-top:1rem;
    margin-bottom:1rem;

    &__photo{
        position:relative;
        border:1px $secondary solid;
        display:flex;
        align-items:center;
        justify-content: center;
        padding:1rem 1rem;
        background:$white;
    }

    &__title{
        font-size:1.5rem;
        border-bottom:1px $secondary solid;
        padding-bottom:0.2rem;
        margin-bottom:0rem;
        font-weight:$font-weight-base;
        margin-top:0.5rem;
        float:left;
    }

    &__product_header{
        overflow: hidden;
    }

    &__code{
        clear: both;
        margin-top:2rem;

        .field{
            display:flex;
            align-items:center;

            &__caption{
                color: $success;
                margin-right:0.4rem;
            }
        }

    }

    &__subtitle{
        margin-top: 3rem;
        padding:0.2rem 0 0.6rem;
    }

    &__actions-wrapper {
        padding: 1rem;

        label {
            font-size: .8rem;
            font-weight: $font-weight-medium;
        }

        .form-control{
            padding: .15rem .15rem;
            font-size: .75rem;
        }
        
        .custom-form-group{
            margin:1rem auto;

            @include media-breakpoint-up(lg){
                margin:auto;
            }

            .btn-success {
                color:$white;

                &:hover {
                    background:$black;
                    border-color:$black;
                }
            }
        }
    }

    &__addtocart-btn{
        a{
            text-decoration: none !important;
        }

        .btn-group-lg>.btn {
            padding: .5rem 1rem;
            font-size: 1rem;
            color:$white;
        }

        .btn-success{
            border-top-right-radius:0!important;
            border-bottom-right-radius:0!important;
        }

        .btn-info{
            border-top-left-radius:0!important;
            border-bottom-left-radius:0!important;
        }
    }

    &__actions{
        display:flex;
        flex-direction:column;
        margin:1rem 0 1rem;

        a{
            margin-top:1rem;
        }

        @include media-breakpoint-up(md){
            flex-direction:row;

            a{
                margin-left:1rem;
                margin-top:0;
            }
        }
    }

    &__attributes{
        margin-bottom:3rem;

        .field{
            display:flex;
            justify-content: flex-start;
        
            &__caption{
                margin-right:0.4rem;
                color:$success;
            }
        
        }
    }

    &__description{
        padding:0.5rem 0.5rem;
        border:3px $secondary solid;
        overflow-wrap: break-word;
        
    }
    &__description-title{
        width: fit-content;
        margin-top: 3rem;
        padding:0.5rem 0.5rem;
        border:3px $secondary solid;
        border-bottom: none;
    }

    &__availability{
        color: $green;
        padding:0.2rem 0 0.6rem;
        font-weight:$font-weight-bold;
        display:flex;
        align-items:center;

        svg{
            font-size:1.3rem;
            margin-right:0.4rem;
        }
    }

    &__notice{
        display:flex;
        align-items:center;
        margin-top:0.5rem;

        svg{
            font-size:1.3rem;
            margin-right:0.4rem;
        }
    }

    &__price{

        &--final{

            .field{
    
                &__caption{
                    font-size: .8rem;
                    font-weight:$font-weight-medium;
                    display: inline-block;
                    float: left;
                    margin-top: 13px;
                    margin-right: 10px;

                    &.with-discount{
                        font-size:1.4375rem;
                        font-weight:$font-weight-medium;
                        text-decoration:line-through;
                        color:#b0b0b0;
                        float:left;
                        margin-top: 5px;
                        margin-right: 10px;
                    }
                }

                &__value{
                    margin-top: -2px;
                    float:left;                    
                }
            }       
        }

        &--discount{

            .field{
    
                &__caption{
                    font-size: .8rem;
                    font-weight:$font-weight-medium;
                    display: inline-block;
                }
    
                &__value{
                    font-size: 1.3rem;
                    color:$primary;
                    font-weight: 700;
                }
            }       
        }

        &--final{

            .field{
    
                &__value{
                    font-size: 1.9375rem;
                    color:$success;
                    font-weight: $font-weight-bold;
                }
            }       
        }
    }

    %border-radius{
        border:1px $gray-300 solid;
        border-radius:0;
    }

    
    &__action{
        &--wishlist{
            font-size:1.7rem;
            margin-left:1rem;
        }
    }

    &__share{
        display:flex;
        justify-content: flex-end;
        align-items: center;

        .caption{
            padding-right:0.5rem;
        }
    }

    &__quantity{
        max-width: 5rem;

        .quantity-btns{
            display:flex;
            padding: 0 2px;
            span{
                display:flex;
                align-items:center;
                padding:0 .5rem;
                border:1px solid $gray-400;
                font-size:$font-size-lg;
                cursor:pointer;

                &:hover{
                    background-color:$secondary;
                }
            }
            .minus{
                // border-right:0;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }

            .plus{
                // border-left:0;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        } 

        input{
            text-align:center;
            border-left:0;
            border-right:0;
        }
    }

    &__tabs{
        margin-top:3rem;
        margin-bottom:3rem;

        .nav-link{
            margin-top:0.2rem;
            padding:1rem 1.5rem;
            background-color:$primary;
            color:$white;
            margin-right:2px;

            &:hover{
                background-color:lighten($primary, 10%);
                color:$white;
            }

            &.active{
                background-color:$success;
            }
        }

        .tab-content{
            background:$white;
            padding:1rem;
        }
    }
}

