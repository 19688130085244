.links{
    list-style:none;
    padding:0;
    margin:0;

    &__link{
        color: $white;
        transition: color 0.5s;

        &:hover{
            color: $info;
            text-decoration:none;
        }
    }

}